import { EventEmitter } from "events";

import Swagger from "swagger-client";

import sessionStore from "./SessionStore";
import {checkStatus, errorHandler, errorHandlerIgnoreNotFound } from "./helpers";
import dispatcher from "../dispatcher";


class EventStore extends EventEmitter {
  constructor() {
    super();
    this.swagger = new Swagger("/swagger/gateway.swagger.json", sessionStore.getClientOpts());
  }


  list(search, organizationID, limit, offset, callbackFunc) {
    this.swagger.then(client => {
      client.apis.GatewayService.ListEvents({
        limit: limit,
        offset: offset,
        organizationID: organizationID,
        search: search,
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }
  listhistory(search, organizationID,deviceType,deviceID,eventType, limit, offset, callbackFunc) {
    this.swagger.then(client => {
      client.apis.GatewayService.ListHistoryEvents({
        limit: limit,
        offset: offset,
        organizationID: organizationID,
        search: search,
        device:	deviceType,
        deviceId:	deviceID,
        type:	eventType,
      })
      .then(checkStatus)
      .then(resp => {
        callbackFunc(resp.obj);
      })
      .catch(errorHandler);
    });
  }

  notify(action) {
    dispatcher.dispatch({
      type: "CREATE_NOTIFICATION",
      notification: {
        type: "success",
        message: "event has been " + action,
      },
    });
  }
}

const eventStore = new EventStore();
export default eventStore;
