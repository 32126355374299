import React from "react";

import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import FormControl from "../../components/FormControl";


class NetworkServerForm extends FormComponent {
  constructor() {
    super();
    this.state = {
      tab: 0,
    };

    this.onChangeTab = this.onChangeTab.bind(this);
  }

  onChangeTab(event, value) {
    this.setState({
      tab: value,
    });
  }

  render() {
    if (this.state.object === undefined) {
      return(null);
    }

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
      >
            <Tabs
              value={this.state.tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={this.onChangeTab}
            >
              <Tab label="通用" />
              <Tab label="网关发现" />
              <Tab label="TLS证书" />
            </Tabs>
          {this.state.tab === 0 && <div>
            <TextField
              id="name"
              label="Network服务器名称"
              fullWidth={true}
              margin="normal"
              value={this.state.object.name || ""}
              onChange={this.onChange}
              helperText="标识network服务器的名称"
              required={true}
            />
            <TextField
              id="server"
              label="network服务器地址"
              fullWidth={true}
              margin="normal"
              value={this.state.object.server || ""}
              onChange={this.onChange}
              helperText="格式：'hostname:port'，例如：'localhost:8000'。"
              required={true}
            />
          </div>}
          {this.state.tab === 1 && <div>
            <FormControl
              label="网关发现"
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      id="gatewayDiscoveryEnabled"
                      checked={!!this.state.object.gatewayDiscoveryEnabled}
                      onChange={this.onChange}
                      value="true"
                      color="primary"
                    />
                  }
                  label="启用网关发现"
                />
              </FormGroup>
              <FormHelperText>启用这个network服务器的网关发现功能</FormHelperText>
            </FormControl>
            {this.state.object.gatewayDiscoveryEnabled && <TextField
              id="gatewayDiscoveryInterval"
              label="间隔（天）"
              type="number"
              fullWidth={true}
              margin="normal"
              value={this.state.object.gatewayDiscoveryInterval}
              onChange={this.onChange}
              helperText="Lora App Server将每隔该天数向每个网关广播'pings'。"
              required={true}
            />}
            {this.state.object.gatewayDiscoveryEnabled && <TextField
              id="gatewayDiscoveryTXFrequency"
              label="TX 频率（Hz）"
              type="number"
              fullWidth={true}
              margin="normal"
              value={this.state.object.gatewayDiscoveryTXFrequency}
              onChange={this.onChange}
              helperText="用于发送网关发现“pings”的频率（HZ）。请参阅Lorawan区域参数规范，了解每个区域的有效信道。"
              required={true}
            />}
            {this.state.object.gatewayDiscoveryEnabled && <TextField
              id="gatewayDiscoveryDR"
              label="TX data-rate"
              type="number"
              fullWidth={true}
              margin="normal"
              value={this.state.object.gatewayDiscoveryDR}
              onChange={this.onChange}
              helperText="用于传输网关发现“pings”的数据速率。请参考Lorawan地区参数规范，了解每个地区的有效数据率。"
              required={true}
            />}
          </div>}
          {this.state.tab === 2 && <div>
            <FormControl
              label="LoRa App Server链接LoRa Server的证书"
            >
              <FormGroup>
                <TextField
                  id="caCert"
                  label="CA证书"
                  fullWidth={true}
                  margin="normal"
                  value={this.state.object.caCert || ""}
                  onChange={this.onChange}
                  helperText="将CA证书（PEM）文件的内容粘贴到上面的文本框中。留空可禁用TLS。"
                  multiline
                  rows="4"
                />
                <TextField
                  id="tlsCert"
                  label="TLS证书"
                  fullWidth={true}
                  margin="normal"
                  value={this.state.object.tlsCert || ""}
                  onChange={this.onChange}
                  helperText="将TLS证书（PEM）文件的内容粘贴到上述文本框中。留空可禁用TLS。"
                  multiline
                  rows="4"
                />
                <TextField
                  id="tlsKey"
                  label="TLS秘钥"
                  fullWidth={true}
                  margin="normal"
                  value={this.state.object.tlsKey || ""}
                  onChange={this.onChange}
                  helperText="在上面的文本框中粘贴TLS密钥（PEM）文件的内容。留空以禁用TLS。注意：由于安全原因，TLS密钥在提交后无法检索（该字段留空）。使用空的tls密钥字段（但填充了tls证书字段）重新提交表单时，不会覆盖密钥。"
                  multiline
                  rows="4"
                />
              </FormGroup>
            </FormControl>

            <FormControl
              label="LoRa Server链接LoRa App Server的证书"
            >
              <FormGroup>
                <TextField
                  id="routingProfileCACert"
                  label="CA证书"
                  fullWidth={true}
                  margin="normal"
                  value={this.state.object.routingProfileCACert || ""}
                  onChange={this.onChange}
                  helperText="将CA证书（PEM）文件的内容粘贴到上面的文本框中。留空可禁用TLS。"
                  multiline
                  rows="4"
                />
                <TextField
                  id="routingProfileTLSCert"
                  label="TLS证书"
                  fullWidth={true}
                  margin="normal"
                  value={this.state.object.routingProfileTLSCert || ""}
                  onChange={this.onChange}
                  helperText="将TLS证书（PEM）文件的内容粘贴到上述文本框中。留空可禁用TLS。"
                  multiline
                  rows="4"
                />
                <TextField
                  id="routingProfileTLSKey"
                  label="TLS秘钥"
                  fullWidth={true}
                  margin="normal"
                  value={this.state.object.routingProfileTLSKey || ""}
                  onChange={this.onChange}
                  helperText="在上面的文本框中粘贴TLS密钥（PEM）文件的内容。留空以禁用TLS。注意：由于安全原因，TLS密钥在提交后无法检索（该字段留空）。使用空的tls密钥字段（但填充了tls证书字段）重新提交表单时，不会覆盖密钥。"
                  multiline
                  rows="4"
                />
              </FormGroup>
            </FormControl>
          </div>}
      </Form>
    );
  }
}

export default NetworkServerForm;
