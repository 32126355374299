import React, { Component } from 'react';

import { TileLayer } from 'react-leaflet';


class MapTileLayer extends Component {
  render() {
    return(
      <TileLayer
        url='http://webrd0{s}.is.autonavi.com/appmaptile?lang=zh_cn&size=1&scale=1&style=8&x={x}&y={y}&z={z}'
        subdomains='1234'
        attribution='&copy; <a href="https://www.amap.com/">高德地图</a>'
      />
    )
  }
}

export default MapTileLayer;
