import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from '@material-ui/core/Button';

import Plus from "mdi-material-ui/Plus";

import TableCellLink from "../../components/TableCellLink";
import DataTable from "../../components/DataTable";
import ApplicationStore from "../../stores/ApplicationStore";
import theme from "../../theme";

import moment from "moment";

const styles = {
  buttons: {
    textAlign: "right",
  },
  button: {
    marginLeft: 2 * theme.spacing.unit,
  },
  icon: {
    marginRight: theme.spacing.unit,
  },
};


class ListEventsDataUp extends Component {
  constructor() {
    super();
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
  }

  getPage(limit, offset, callbackFunc) {
    ApplicationStore.listEventsDataUp(this.props.match.params.applicationID, limit, offset, callbackFunc);
  }
  
  getRow(obj) {
	  let adr = "n/a";
	  let applicationID = "n/a";
	  let applicationName = "n/a";
	  let data = "n/a";
	  let devEUI = "n/a";
	  let devName = "n/a";
	  let dr = "n/a";
	  let fCnt = "n/a";
	  let fPort = "n/a";
	  let frequency = "n/a";
	  let id = "n/a";
	  let receivedAt = "n/a";
	  let rxInfo = "n/a";
	  
  
	  adr = obj.adr;
	  applicationID = obj.applicationID;
	  applicationName = obj.applicationName;
	  data = obj.data;
	  devEUI = obj.devEUI;
	  devName = obj.devName;
	  dr = obj.dr;
	  fCnt = obj.fCnt;
	  fPort = obj.fPort;
	  frequency = obj.frequency;
	  id = obj.id;	  
	  rxInfo = JSON.stringify(obj.rxInfo);

	  receivedAt = moment(obj.receivedAt).format("YYYY-MM-DD HH:mm:ss.SSS");
	  
  
	  return(
		<TableRow key={obj.id}>
		  <TableCell>{devEUI}</TableCell>
		  <TableCell>{receivedAt}</TableCell>
		  <TableCell>{data}</TableCell>
		  <TableCell>{frequency}</TableCell>
		  <TableCell>{dr}</TableCell>
		  <TableCell>{fCnt}</TableCell>
		  <TableCell>{fPort}</TableCell>
		  <TableCell>{obj.rxInfoStr}</TableCell>
		</TableRow>
	  );

  }

  render() {
    return(
      <Grid container spacing={24}>
        
        <Grid item xs={12}>
          <DataTable
            header={
              <TableRow>
                <TableCell>devEUI</TableCell>
                <TableCell>接收时间</TableCell>
                <TableCell>数据</TableCell>
                <TableCell>频率</TableCell>
                <TableCell>dr</TableCell>
                <TableCell>fCnt</TableCell>
                <TableCell>fPort</TableCell>
                <TableCell>网关信息</TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ListEventsDataUp);
