import React, { Component } from "react";

import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import Plus from "mdi-material-ui/Plus";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TableCellLink from "../../components/TableCellLink";
import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";
import Admin from "../../components/Admin";
import ApplicationStore from "../../stores/ApplicationStore";
import { Link } from 'react-router-dom';


class ListApplications extends Component {
  constructor() {
    super();
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
  }

  getPage(limit, offset, callbackFunc) {
    ApplicationStore.list("", this.props.match.params.organizationID, limit, offset, callbackFunc);
  }
  updateSecKey(appId,appName) {
  
    if (window.confirm("确实更新ID为" +appId + "的" + appName +"应用吗？这也将中断此应用的MQTT链接")) {
      ApplicationStore.updateSecKey(appId, resp => {
        //this.props.history.push(`/organizations/${this.props.match.params.organizationID}/applications`);
	  	window.location.reload(); 
      });
    }
  }


  getRow(obj) {
    return(
      <TableRow key={obj.id}>
        <TableCell>{obj.id}</TableCell>
        <TableCellLink to={`/organizations/${this.props.match.params.organizationID}/applications/${obj.id}`}>{obj.name}</TableCellLink>
        <TableCellLink to={`/organizations/${this.props.match.params.organizationID}/service-profiles/${obj.serviceProfileID}`}>{obj.serviceProfileName}</TableCellLink>
        <TableCell>{obj.description}</TableCell>
        <TableCell>{obj.secKey}
			<a href="#" onClick={this.updateSecKey.bind(obj,obj.id,obj.name)} >更新</a>
		</TableCell>
        <TableCell>
        	{obj.isOnline > 0 ? "在线" : "离线"}
        </TableCell>
	
      </TableRow>
    );
  }

  render() {
    return(
      <Grid container spacing={24}>
        <TitleBar
          buttons={
            <Admin organizationID={this.props.match.params.organizationID}>
              <TitleBarButton
                label="新建"
                icon={<Plus />}
                to={`/organizations/${this.props.match.params.organizationID}/applications/create`}
              />
            </Admin>
          }
        >
          <TitleBarTitle title="应用" />
        </TitleBar>
        <Grid item xs={12}>
          <DataTable
            header={
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>名称</TableCell>
                <TableCell>服务能力</TableCell>
                <TableCell>描述</TableCell>
                <TableCell>secKey</TableCell>
                <TableCell>应用连接</TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default ListApplications;
