import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import Plus from "mdi-material-ui/Plus";

import moment from "moment";
import { Bar } from "react-chartjs-2";

import TitleBar from "../../components/TitleBar";
import TitleBarTitle from "../../components/TitleBarTitle";
import TableCellLink from "../../components/TableCellLink";
import TitleBarButton from "../../components/TitleBarButton";
import DataTable from "../../components/DataTable";
import Admin from "../../components/Admin";
import EventStore from "../../stores/EventStore";


class EventRow extends Component {
  constructor() {
    super();

  }

  componentWillMount() {
    
  }

  render() {
	let eventTime = "";
	let deviceType = "网关";
	let eventType = "上线";
	let eventLevel = "正常事件";
	
		
    eventTime = moment(this.props.event.time).format("YYYY-MM-DD HH:mm:ss.SSS");

	if(this.props.event.device == 0) {
		deviceType = "网关";
	}
	if(this.props.event.type == 20) {
		eventType = "掉线";
	}else if(this.props.event.type == 21) {
		eventType = "上线";
	}

	if(this.props.event.level == 0) {
		eventLevel = "可忽略";
	}else if(this.props.event.level == 1) {
		eventLevel = "小事件";
	}else if(this.props.event.level == 2) {
		eventLevel = "正常事件";
	}else if(this.props.event.level == 3) {
		eventLevel = "大事件";
	}else if(this.props.event.level == 4) {
		eventLevel = "紧急事件";
	}
	

    return(
      <TableRow>
      	  <TableCell>{this.props.event.id}</TableCell>
      	  <TableCell>{deviceType}</TableCell>
      	  <TableCellLink to={`/organizations/${this.props.event.organizationID}/gateways/${this.props.event.deviceId}`}>{this.props.event.deviceId}</TableCellLink>
		  <TableCell>{eventType}</TableCell>
		  <TableCell>{eventLevel}</TableCell>
		  <TableCell>{this.props.event.describe}</TableCell>
		  <TableCell>{eventTime}</TableCell>
		  <TableCellLink to={`/organizations/${this.props.event.organizationID}/device/${this.props.event.device}/id/${this.props.event.deviceId}/type/${this.props.event.type}`}>{this.props.event.count}</TableCellLink>
      </TableRow>
    );
  }
}


const styles = {
  chart: {
    width: 380,
  },
};



class ListEvents extends Component {
  constructor() {
    super();
    this.getPage = this.getPage.bind(this);
    this.getRow = this.getRow.bind(this);
  }

  getPage(limit, offset, callbackFunc) {
    EventStore.list("", this.props.match.params.organizationID, limit, offset, callbackFunc);
  }

  getRow(obj) {
    return(
      <EventRow key={obj.id} event={obj} />
    );
  }

  render() {
    return(
      <Grid container spacing={24}>
        <TitleBar
          
        >
          <TitleBarTitle title="最新事件" />
        </TitleBar>
        <Grid item xs={12}>
          <DataTable
            header={
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>设备类型</TableCell>
                <TableCell>设备ID</TableCell>
                <TableCell>事件类型</TableCell>
                <TableCell>事件级别</TableCell>
                <TableCell>事件内容</TableCell>
                <TableCell>时间</TableCell>
                <TableCell>发生次数</TableCell>
              </TableRow>
            }
            getPage={this.getPage}
            getRow={this.getRow}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(ListEvents);
