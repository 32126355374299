import React from "react";

import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

import FormControl from "../../components/FormControl";
import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";



class OrganizationForm extends FormComponent {
  render() {
    if (this.state.object === undefined) {
      return(<div></div>);
    }

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
      >
        <TextField
          id="name"
          label="组织单位名称"
          helperText="名称只能包含单词、数字和破折号。"
          margin="normal"
          value={this.state.object.name || ""}
          onChange={this.onChange}
          inputProps={{
            pattern: "[\\w-]+",
          }}
          required
          fullWidth
        />
        <TextField
          id="displayName"
          label="显示名字"
          margin="normal"
          value={this.state.object.displayName || ""}
          onChange={this.onChange}
          required
          fullWidth
        />
        <FormControl
          label="网关"
        >
          <FormGroup>
            <FormControlLabel
              label="组织单位可拥有网关"
              control={
                <Checkbox
                  id="canHaveGateways"
                  checked={!!this.state.object.canHaveGateways}
                  onChange={this.onChange}
                  value="true"
                  color="primary"
                />
              }
            />
          </FormGroup>
          <FormHelperText>选中时，意味着组织单位管理员可以将自己的网关添加到网络中。请注意，网关的使用不限于此组织单位。</FormHelperText>
        </FormControl>
      </Form>
    );
  }
}

export default OrganizationForm;
