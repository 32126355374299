import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from "@material-ui/core/FormHelperText";
import FormGroup from "@material-ui/core/FormGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from '@material-ui/core/Checkbox';
import Button from "@material-ui/core/Button";

import { Map, Marker } from 'react-leaflet';

import FormComponent from "../../classes/FormComponent";
import Form from "../../components/Form";
import AutocompleteSelect from "../../components/AutocompleteSelect";
import NetworkServerStore from "../../stores/NetworkServerStore";
import GatewayProfileStore from "../../stores/GatewayProfileStore";
import LocationStore from "../../stores/LocationStore";
import MapTileLayer from "../../components/MapTileLayer";
import theme from "../../theme";


const boardStyles = {
  formLabel: {
    color: theme.palette.primary.main,
  },
  a: {
    color: theme.palette.primary.main,
  },
};

class GatewayBoardForm extends Component {
  constructor() {
    super();

    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }

  onChange(e) {
    let board = this.props.board;
    const field = e.target.id;

    board[field] = e.target.value;
    this.props.onChange(board);
  }

  onDelete(e) {
    e.preventDefault();
    this.props.onDelete();
  }

  render() {
    return(
      <FormControl fullWidth margin="normal">
        <FormLabel className={this.props.classes.formLabel}>板 #{this.props.i} 配置 (<a href="#delete" onClick={this.onDelete} className={this.props.classes.a}>删除</a>)</FormLabel>
        <TextField
          id="fpgaID"
          label="FPGA ID"
          margin="normal"
          value={this.props.board.fpgaID || ""}
          onChange={this.onChange}
          inputProps={{
            pattern: "[A-Fa-f0-9]{16}",
          }}
          placeholder="0000000000000000"
          helperText="geoloc集中器板的fpga id。这只适用于具有地理定位功能的v2网关。（可选）"
          fullWidth
        />
        <TextField
          id="fineTimestampKey"
          label="高精度时间戳解密秘钥"
          margin="normal"
          value={this.props.board.fineTimestampKey || ""}
          onChange={this.onChange}
          inputProps={{
            pattern: "[A-Fa-f0-9]{32}",
          }}
          placeholder="000000000000000000000000000000000"
          helperText="高精度时间戳AES解密密钥。设置后，lora服务器将解密高精度时间戳。这只适用于具有地理定位功能的v2网关。（可选）"
          fullWidth
        />
      </FormControl>
    );
  }
}

GatewayBoardForm = withStyles(boardStyles)(GatewayBoardForm);


const styles = {
  mapLabel: {
    marginBottom: theme.spacing.unit,
  },
  link: {
    color: theme.palette.primary.main,
  },
  formLabel: {
    fontSize: 12,
  },
};

class GatewayForm extends FormComponent {
  constructor() {
    super();
    
    this.state = {
      mapZoom: 15,
    };

    this.getNetworkServerOption = this.getNetworkServerOption.bind(this);
    this.getNetworkServerOptions = this.getNetworkServerOptions.bind(this);
    this.getGatewayProfileOption = this.getGatewayProfileOption.bind(this);
    this.getGatewayProfileOptions = this.getGatewayProfileOptions.bind(this);
    this.setCurrentPosition = this.setCurrentPosition.bind(this);
    this.updatePosition = this.updatePosition.bind(this);
    this.updateZoom = this.updateZoom.bind(this);
    this.addGatewayBoard = this.addGatewayBoard.bind(this);
  }

  componentDidMount() {
    super.componentDidMount();

    if (!this.props.update) {
      this.setCurrentPosition();
    }
  }

  onChange(e) {
    if (e.target.id === "networkServerID" && e.target.value !== this.state.object.networkServerID) {
      let object = this.state.object;
      object.gatewayProfileID = null;
      this.setState({
        object: object,
      });
    }

    super.onChange(e);
  }

  setCurrentPosition(e) {
    if (e !== undefined) {
      e.preventDefault();
    }

    LocationStore.getLocation(position => {
      let object = this.state.object;
      object.location = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      }
      this.setState({
        object: object,
      });
    });
  }

  updatePosition() {
    const position = this.refs.marker.leafletElement.getLatLng();
    let object = this.state.object;
    object.location = {
      latitude: position.lat,
      longitude: position.lng,
    }
    this.setState({
      object: object,
    });
  }

  updateZoom(e) {
    this.setState({
      mapZoom: e.target.getZoom(),
    });
  }

  getNetworkServerOption(id, callbackFunc) {
    NetworkServerStore.get(id, resp => {
      callbackFunc({label: resp.networkServer.name, value: resp.networkServer.id});
    });
  }

  getNetworkServerOptions(search, callbackFunc) {
    NetworkServerStore.list(this.props.match.params.organizationID, 999, 0, resp => {
      const options = resp.result.map((ns, i) => {return {label: ns.name, value: ns.id}});
      callbackFunc(options);
    });
  }

  getGatewayProfileOption(id, callbackFunc) {
    GatewayProfileStore.get(id, resp => {
      callbackFunc({label: resp.gatewayProfile.name, value: resp.gatewayProfile.id});
    });
  }

  getGatewayProfileOptions(search, callbackFunc) {
    if (this.state.object === undefined || this.state.object.networkServerID === undefined) {
      callbackFunc([]);
      return;
    }

    GatewayProfileStore.list(this.state.object.networkServerID, 999, 0, resp => {
      const options = resp.result.map((gp, i) => {return {label: gp.name, value: gp.id}});
      callbackFunc(options);
    });
  }

  addGatewayBoard() {
    let object = this.state.object;
    if (object.boards === undefined) {
      object.boards = [{}];
    } else {
      object.boards.push({});
    }

    this.setState({
      object: object,
    });
  }

  deleteGatewayBoard(i) {
    let object = this.state.object;
    object.boards.splice(i, 1);
    this.setState({
      object: object,
    });
  }

  updateGatewayBoard(i, board) {
    let object = this.state.object;
    object.boards[i] = board;
    this.setState({
      object: object,
    });
  }

  render() {
    if (this.state.object === undefined) {
      return(<div></div>);
    }

    const style = {
      height: 400,
    };

    let position = [];
    if (this.state.object.location.latitude !== undefined && this.state.object.location.longitude !== undefined) {
      position = [this.state.object.location.latitude, this.state.object.location.longitude];
    } else {
      position = [0, 0];
    }

    let boards = [];
    if (this.state.object.boards !== undefined) {
      boards = this.state.object.boards.map((b, i) => <GatewayBoardForm key={i} i={i} board={b} onDelete={() => this.deleteGatewayBoard(i)} onChange={board => this.updateGatewayBoard(i, board)} />);
    }


	let newAppSwVersion = "";
    if (this.state.object.newAppSwVersion !== undefined) {
      newAppSwVersion = this.state.object.newAppSwVersion;
    }
	let newAppSwConf = "";
    if (this.state.object.newAppSwConf !== undefined) {
      newAppSwConf = this.state.object.newAppSwConf;
    }
	let newSysSwVersion = "";
    if (this.state.object.newSysSwVersion !== undefined) {
      newSysSwVersion = this.state.object.newSysSwVersion;
    }
	let newSysSwConf = "";
    if (this.state.object.newSysSwConf !== undefined) {
      newSysSwConf = this.state.object.newSysSwConf;
    }

    return(
      <Form
        submitLabel={this.props.submitLabel}
        onSubmit={this.onSubmit}
        extraButtons={<Button onClick={this.addGatewayBoard}>增加小板配置</Button>}
      >
        <TextField
          id="name"
          label="网关名称"
          margin="normal"
          value={this.state.object.name || ""}
          onChange={this.onChange}
          inputProps={{
            pattern: "[\\w-]+",
          }}
          helperText="名称只能包含单词、数字和破折号。"
          required
          fullWidth
        />
        <TextField
          id="description"
          label="网关描述"
          margin="normal"
          value={this.state.object.description || ""}
          onChange={this.onChange}
          rows={4}
          multiline
          required
          fullWidth
        />
        {!this.props.update && <TextField
          id="id"
          label="网关ID"
          margin="normal"
          value={this.state.object.id || ""}
          onChange={this.onChange}
          inputProps={{
            pattern: "[A-Fa-f0-9]{16}",
          }}
          placeholder="0000000000000000"
          required
          fullWidth
        />}

		{this.props.update && <TextField
          id="newAppSwVersion"
          label="新软件版本号"
          margin="normal"
          value={newAppSwVersion}
          onChange={this.onChange}
		  required
          fullWidth
        />}
		{this.props.update && <TextField
          id="newAppSwConf"
          label="新软件配置"
          margin="normal"
          value={newAppSwConf}
          onChange={this.onChange}
          rows={2}
          multiline
          required
          fullWidth
        />}
		{this.props.update && <TextField
          id="newSysSwVersion"
          label="新系统软件版本号"
          margin="normal"
          value={newSysSwVersion}
          onChange={this.onChange}
		  required
          fullWidth
        />}
		{this.props.update && <TextField
          id="newSysSwConf"
          label="新系统软件配置"
          margin="normal"
          value={newSysSwConf}
          onChange={this.onChange}
          rows={2}
          multiline
          required
          fullWidth
        />}
		
        {!this.props.update && <FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel} required>Network服务器</FormLabel>
          <AutocompleteSelect
            id="networkServerID"
            label="选择Network服务器"
            value={this.state.object.networkServerID || ""}
            onChange={this.onChange}
            getOption={this.getNetworkServerOption}
            getOptions={this.getNetworkServerOptions}
          />
          <FormHelperText>
            选择网关将连接到的Network服务器。如果下拉列表中没有可用的Network服务器，请确保此组织单位有服务能力。
          </FormHelperText>
        </FormControl>}
        <FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.formLabel}>网关能力</FormLabel>
          <AutocompleteSelect
            id="gatewayProfileID"
            label="选择网关能力"
            value={this.state.object.gatewayProfileID || ""}
            triggerReload={this.state.object.networkServerID || ""}
            onChange={this.onChange}
            getOption={this.getGatewayProfileOption}
            getOptions={this.getGatewayProfileOptions}
            inputProps={{
              clearable: true,
              cache: false,
            }}
          />
          <FormHelperText>
            可以分配给网关的网关能力。当LoRa Gateway Bridge配置为管理packet-forwarder configuration时，此配置会自动配置给网关。
          </FormHelperText>
        </FormControl>
        <FormGroup>
          <FormControlLabel
            label="启用网关发现"
            control={
              <Checkbox
                id="discoveryEnabled"
                checked={!!this.state.object.discoveryEnabled}
                onChange={this.onChange}
                color="primary"
              />
            }
          />
          <FormHelperText>
            当启用（并且lora服务器配置为启用网关发现功能）时，网关将发送定期ping以测试同一网络中其他网关的覆盖率。
          </FormHelperText>
        </FormGroup>
        <TextField
          id="location.altitude"
          label="网关海拔（米）"
          margin="normal"
          type="number"
          value={this.state.object.location.altitude || 0}
          onChange={this.onChange}
          helperText="当网关具有集成GPS时，当网络从网关接收到统计信息时，将自动设置该值。"
          required
          fullWidth
        />
        <FormControl fullWidth margin="normal">
          <FormLabel className={this.props.classes.mapLabel}>网关位置(<a onClick={this.setCurrentPosition} href="#getlocation" className={this.props.classes.link}>设置为当前位置</a>)</FormLabel>
          <Map
            center={position}
            zoom={this.state.mapZoom}
            style={style}
            animate={true}
            scrollWheelZoom={false}
            onZoomend={this.updateZoom}
            >
            <MapTileLayer />
            <Marker position={position} draggable={true} onDragend={this.updatePosition} ref="marker" />
          </Map>
          <FormHelperText>
            将标记拖到网关的位置。当网关具集成GPS时，当网络从网关接收到统计信息时，此值将自动设置。
          </FormHelperText>
        </FormControl>
        {boards}
      </Form>
    );
  }
}

export default withStyles(styles)(GatewayForm);
